<template>
  <div class='pa-6'>
    <v-sheet tile height='30' class='d-flex'>
      <h2>体験レッスンご予約フォーム</h2>
    </v-sheet>
    <v-container class='pb-0'>
      <v-row justify='center' dense>
        <v-col cols=12 lg=3 md=4 sm=6>
          <v-select
            v-model='selectShop'
            item-text='name'
            item-value='id'
            :items='shops'
            dense
            outlined
            hide-details
            label='店舗'
            class='ma-2'
            return-object
          ></v-select>
        </v-col>
        <v-col cols=12 lg=3 md=4 sm=6>
          <v-sheet
            tile
            height="60"
            class="d-flex"
          >
          <Datepicker :selectDay='selectDay' @input='selectDay = $event'></Datepicker>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
    <!-- main -->
    <v-container>
      <v-row justify='center'>
        <transition name="fade" mode="out-in">
          <v-skeleton-loader
            v-if="loading"
            type="table-tbody, table-tbody"
          ></v-skeleton-loader>
        </transition>
        <v-col cols=12 lg=4 md=6 sm=6
          v-for='(emptySeat, key) in emptyInfo'
          :key='key'
        >
          <v-card class='mb-8' max-width='500'>
            <v-card-title class='white--text blue light-2 text-body-2 pa-2'>
              <v-btn text small class='white--text px-1' @click='prev()' >
                <v-icon>mdi-chevron-left</v-icon>
                前日
              </v-btn>
              <v-spacer></v-spacer>
              <div v-if='emptySeat.byTime !== "empty"'>
              {{ changeFormat(emptySeat.schedule.date) }}
              </div>
              <div v-else>
              {{ getSelectDay(key) }}
              </div>
              <v-spacer></v-spacer>
              <v-btn text small class='white--text px-1' @click='next()' >
                翌日
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text class='pt-4' v-if='isBookable(emptySeat)'>
              担当コーチ： {{ groupByInstructor(emptySeat.schedule.instructors) }}
            </v-card-text>
            <v-card-text class='pt-4' v-else>
              担当コーチ： 未定
            </v-card-text>
            <v-divider></v-divider>
            <div v-if='isBookable(emptySeat)'>
              <v-list-item
                v-for="item in emptySeat.byTime"
                :key="item.time"
              >
                <v-list-item-content>
                  <v-list-item-title>{{ item.time }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn
                    depressed
                    class='white--text'
                    color='blue darken-2'
                    @click='confirm(item, SHIFT_TYPE.trial.text)'
                    :disabled='isDisable(item)'
                  >
                    {{ isDisable(item) ? '予約不可' : '予約可能' }}
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </div>
            <v-alert
              v-else
              type="error"
              icon="mdi-alert-outline"
              text
            >
              予約を受付けていません。
            </v-alert>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <trial-entry-dialog v-model='dialog' :editedItem='editedItem' :isLefty='isLefty'></trial-entry-dialog>
  </div>
</template>
<script>
import lodash from 'lodash';
import moment from 'moment';
import util from '../util';
import config from '../mixins/config';
import Datepicker from '../components/Datepicker';
import TrialEntryDialog from '../components/TrialEntryDialog';

export default {
  components: {
    Datepicker,
    TrialEntryDialog,
  },
  mixins: [config],
  data: () => ({
    shops: [],
    selectShop: '',
    dialog: false,
    selectDay: moment(new Date()).format('YYYY-MM-DD'),
    today: moment(new Date()).format('YYYY-MM-DD'),
    currentTime: moment(),
    emptyInfo: [],
    editedItem: {},
    valid: true,
    // 入力規則
    formRules: {
      required: value => !!value || '必ず選択してください。',
    },
    isLefty: false,
    loading: false,
  }),
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'New Item' : 'Edit Item';
    },
  },
  watch: {
    async selectShop() {
      await this.getEmptySeat();
    },
    async selectDay() {
      await this.getEmptySeat();
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      this.loading = true;
      this.shops = await util.callCloudFunction('getShop');
      this.selectShop = this.shops[0];
    },
    async getEmptySeat() {
      this.loading = true;
      const currentDay = await util.callCloudFunction('getEmptySheat', { date: this.selectDay, shopId: this.selectShop.id, isNotMember: true });
      const nextDay = await util.callCloudFunction('getEmptySheat', { date: moment(this.selectDay).add(1, 'day').format('YYYY-MM-DD'), shopId: this.selectShop.id, isNotMember: true });
      this.emptyInfo = [];
      this.emptyInfo.push(currentDay);
      this.emptyInfo.push(nextDay);
      this.loading = false;
    },
    groupByInstructor(array) {
      const union = lodash.unionBy(array, 'uid').map(v => v.name);
      return lodash.join(lodash.pull(union, 'なし'), ', ');
    },
    isBookable(emptySeat) {
      return emptySeat !== 'empty' && emptySeat.byTime.some((v) => v.remainingLesson > 0 && v.trialCount < v.trialMaxCount);
    },
    confirm(item, type, isLefty = false) {
      this.editedItem = Object.assign({}, item);
      this.editedItem.date = {};
      this.editedItem.date.date = item.date;
      this.editedItem.date.time = item.time;
      this.isLefty = isLefty;
      this.editedItem.type = type;
      this.editedItem.shop = this.selectShop;
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.$refs.form.resetValidation();
      });
    },
    prev() {
      this.selectDay = moment(this.selectDay).add(-1, 'day').format('YYYY-MM-DD');
    },
    next() {
      this.selectDay = moment(this.selectDay).add(1, 'day').format('YYYY-MM-DD');
    },
    isDisable(item) {
      // 1組以上の体験予約があれば、体験予約の最大数未満でも予約不可
      if (item.trialCount >= 1) {
        return true;
      }
      // 既にレッスンが何件か入っており、フリーに変更する余剰がない場合は空きなしとみなす。
      if (item.trialCount === 0 && item.remainingLesson - (item.lessonToFree + 1) < 0) {
        return true;
      }
      const isTrialAcceptable = item.remainingLesson > 0 && item.trialCount < item.trialMaxCount;
      if (item.date === this.today) {
      // 開始時刻から20分以上経過で予約不可とする。
        const limit = 20;
        const entryTime = moment(item.date + item.time, 'YYYY-MM-DD HH:mm');
        return this.currentTime.diff(entryTime, 'minutes') >= limit || !isTrialAcceptable;
      }
      return !isTrialAcceptable;
    },
    changeFormat(date) {
      return moment(date).locale('ja').format('M/D (dd)');
    },
    getSelectDay(key) {
      return moment(this.selectDay).add(key, 'day').locale('ja').format('M/D (dd)');
    },
    setSnackbar(res) {
      this.snackbarParam = res;
      this.snackbar = true;
    },
  },
};
</script>
<style>
.v-messages__message {
  line-height: 13px !important;
}
</style>
